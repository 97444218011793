@use "../../scss/utils/functions" as fn;

.main {
	.chatContent {
		z-index: 1000000;
		pointer-events: all;
		position: absolute;
		bottom: 0rem;
		right: 0rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		// border: 1px solid red;
		z-index: 100;
		width: 100vw;
		height: 90vh;
		border-radius: 10px 10px 0 0;
		padding: 10px;

		@media screen and (min-width: 768px) {
			width: 65vw;
		}

		@media screen and (min-width: 1024px) {
			width: 46vw;
			height: initial;
			right: 1rem;
			bottom: 1rem;
			top: 1rem;
			border-radius: 15px;
		}

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px;
			border-radius: 10px 10px 0 0;

			@media screen and (min-width: 1024px) {
				border-radius: 15px 15px 0 0;
			}

			.logoContainer {
				display: flex;
				gap: 10px;
				width: max-content;

				.logo {
					width: 32px;
					height: 32px;
					border-radius: 4px;
				}

				.snapshot {
					display: flex;
					flex-direction: column;
					font-size: 14px;
					color: #000;
					font-weight: 700;
				}

				.poweredBy {
					font-size: 10px;
					margin-top: -2px;
				}
			}

			.close {
				cursor: pointer;
				width: 32px;
				height: 32px;
			}
		}

		.headerAlt {
			background: #ffffff;
			border-bottom: 1px solid #efefef;
		}

		.chatContainer {
			background-color: transparent;
			position: relative;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			scrollbar-width: none;
			-ms-overflow-style: none;
			padding: 10px 16px 25px;
			display: flex;
			flex-direction: column;
			display: flex;
			flex-direction: column;
			align-content: flex-end;
			background: #ffffff;
			padding-bottom: 58px;

			.chats123 {
				display: flex;
				width: 100%;
				flex-direction: column;

				.promptGroupp {
					animation: typing 500ms ease-in-out forwards;
					max-width: 100%;
					margin-block: 30px;

					.promptWrapper {
						display: flex;
						flex-direction: column;
						border-radius: 8px;

						.prompt {
							align-self: flex-end;
							word-break: break-word;
							background: transparent;
							color: #444;
							font-size: 15px;
							font-weight: 400;
							border-radius: 6px;
							background-color: #f5f7f6;
							padding: 8px;
						}
					}
				}
				.responseContainer {
					display: flex;
					gap: 17px;
					height: 100%;
					width: 100%;

					.snapshot {
						width: 36px;
						height: 36px;
						border-radius: 50%;
						display: none;
					}

					.response {
						align-self: flex-start;
						color: #444;
						font-size: 15px;
						font-weight: 400;
						width: 100%;
						word-break: break-word;

						// animation: typing 500ms ease-in-out forwards;
					}
				}

				@keyframes typing {
					from {
						opacity: 0;
						transform: translateY(10px);
					}

					to {
						opacity: 1;
						transform: translateY(0);
					}
				}
			}

			.feedbackContainer {
				display: grid;
				grid-template-columns: 28px auto;
				align-items: flex-start;
				gap: 10px;

				.loadingContainer {
					.logoLoading {
						width: 28px;
						height: 28px;
						border-radius: 50%;
						animation: pulse 1.2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
					}

					@keyframes pulse {
						0%,
						100% {
							opacity: 1;
						}
						50% {
							opacity: 0.3;
						}
					}
				}

				.feedback {
					font-family: "CircularStd", sans-serif;
					font-size: 15px;
					color: #777;
					margin-top: 2.3px;
					background: linear-gradient(
						90deg,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 1) 50%,
						rgba(255, 255, 255, 0) 100%
					);
					-webkit-background-clip: text;
					background-clip: text;
					mask-image: linear-gradient(
						90deg,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 1) 50%,
						rgba(0, 0, 0, 0) 100%
					);
					mask-size: 200% 100%;
					animation: shimmer 1.8s infinite;
				}

				@keyframes shimmer {
					0% {
						mask-position: 100%;
					}
					100% {
						mask-position: -100%;
					}
				}
			}
		}
		.promptBoxChat {
			background: #ffffff;
			border-radius: 0 0 10px 10px;
			padding: 0px 9px;

			@media screen and (min-width: 1024px) {
				border-radius: 0 0 15px 15px;
			}

			.poweredByFooter {
				text-align: center;
				color: #777;
				font-size: 12px;
			}
		}
	}
}