// VARIABLES
$black: #000;
$white: #fff;
$background: #fefefe;
$alt-background: #f9f9f9;
$button-color: #defff1;
$header-color: #0c291d;
$alt-header-color: #515151;
$label-color: #646464;
$text: #686968;
$text-light: #9e9494;
$light-text: #9a9a9a;
$light-ash: #f7f4f4;
$abstract-text: #5a5a5a;
$input-border: #c0c0c0;
$deep-green: #58af8a;
$border-sha: 0px 5px 15px 5px rgba(198, 198, 198, 0.1);
