@use "../../scss/utils/functions" as fn;
.welcome {
	height: 100%;
	display: flex;
	flex-direction: column;

	.top {
        padding: 10px;
		display: flex;
		flex-direction: column;
		gap: 35px;

		.greetingContainer {

			.greeting {
				font-size: 28px;
				max-width: 350px;
				font-weight: 400;
			}

			.spanGreeting {
				font-size: 14.5px;
				max-width: 550px;
				font-weight: 400;
			}
		}
	}

	.bottom {
        position: relative;
		padding: 50px 16px 0;
		width: 100%;
		height: 100%;
		margin-top: 50px;
		background: transparent;
		display: flex;
		flex-direction: column;
		gap: 18px;
		text-align: center;
		background-color: #fff;
		border-radius: 15px;

        .suggestedPrompts{
            text-align: start;
            text-transform: uppercase;
            color: #848484;
        }

		.randomPromptsContainer{
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
			gap: 14px;
			width: 100%;
		.randomPrompt {
			display: flex;
			justify-content: center;
			align-items: center;
			color: #3B3B3B;
			cursor: pointer;
			padding: 12px 16px;
			border-radius: 5px;
			width: 100%;
			font-size: 14px;
			background: linear-gradient(
				142.96deg,
				rgba(202, 202, 202, 0.15) -3.54%,
				rgba(160, 160, 160, 0.138) 95.15%
			);
		}
	}
		.promptBoxHome {
			position: absolute;
			top: -30px;
			width: 95.7%;
		}
	}
}
