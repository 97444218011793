@use "../../../scss/utils/functions" as fn;
@use "../../../scss/global/colors" as *;

.generalModal {
	position: absolute;
	inset: 0;
	background: rgba(0, 0, 0, 0.5);
	transition: all 350ms ease-in-out;
	border-radius: 15px;

	.generalModalContainer {
		height: 100%;

		.generalModalContent {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 350ms ease-in-out;
			opacity: 0;
			height: 100%;

			.modalItem {
				background: #FFFFFF;
				width: 75%;
				transition: transform 220ms ease-in-out, opacity 220ms ease-in-out;
				opacity: 0;
				transform: translateY(20px);
				box-shadow: 2px 2px 18px 2px #00000040;
				border-radius: 8px;
				animation: none;

				@media screen and (max-width:768px) {
					width: 90%;
				}
			}

			.modalItemActive {
				transform: translateY(0);
				opacity: 1;
				animation: slideIn 220ms ease-in-out forwards;
			}

			@keyframes slideIn {
				from {
					opacity: 0;
					transform: translateY(20px);
				}
				to {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		.generalModalActive {
			transform: translateX(0);
			opacity: 1;
		}
	}
}
